import { getNow } from 'kb-shared/utilities/momentHelpers';

import { MedicationInstruction } from './MedicationsWidget.types';

export const medicationInstructionsMock: MedicationInstruction[] = [
  {
    index: 0,
    titleLabel: 'Today',
    dateLabel: 'Today',
    data: {
      date: getNow('YYYY-MM-DD'),
      dateLabel: 'Today',
      dayNumber: 1,
      haveIntercourse: false,
      id: '1',
      instructions: '',
      updatedAt: new Date().toISOString(),
      started: true,
      startDate: getNow('YYYY-MM-DD'),
      drugs: [
        {
          amount: '_ mg',
          dailyRecurrence: 1,
          id: 1,
          name: 'Medication 1',
          timeOfDay: 'AM'
        },
        {
          amount: '_ IU',
          dailyRecurrence: 1,
          id: 2,
          name: 'Medication 2',
          timeOfDay: 'AM'
        },
        {
          amount: '_ mcg',
          dailyRecurrence: 1,
          id: 3,
          name: 'Medication 3',
          timeOfDay: 'AM'
        },
        {
          amount: '_ mcg',
          dailyRecurrence: 1,
          id: 4,
          name: 'Medication 4',
          timeOfDay: 'AM'
        }
      ]
    }
  },
  {
    index: 1,
    titleLabel: 'Tomorrow',
    dateLabel: 'Tomorrow',
    data: {
      date: getNow('YYYY-MM-DD'),
      dateLabel: 'Tomorrow',
      dayNumber: 2,
      haveIntercourse: false,
      id: '2',
      instructions: '',
      updatedAt: new Date().toISOString(),
      started: true,
      startDate: getNow('YYYY-MM-DD'),
      drugs: [
        {
          amount: '_ mcg',
          dailyRecurrence: 1,
          id: 3,
          name: 'Medication 3',
          timeOfDay: 'AM'
        }
      ]
    }
  },
  {
    index: 2,
    titleLabel: 'Next day',
    dateLabel: 'Next day',
    data: {
      date: getNow('YYYY-MM-DD'),
      dateLabel: 'Next day',
      dayNumber: 3,
      haveIntercourse: false,
      id: '3',
      instructions: '',
      updatedAt: new Date().toISOString(),
      started: true,
      startDate: getNow('YYYY-MM-DD'),
      drugs: [
        {
          amount: '_ IU',
          dailyRecurrence: 1,
          id: 2,
          name: 'Medication 2',
          timeOfDay: 'AM'
        }
      ]
    }
  }
];
