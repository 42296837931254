import React from 'react';

import {
  HandsHoldingHeart,
  MoneyCheckDollar,
  StaffSnake,
  UserGear,
  Headset,
  HandHoldingHeart
} from 'assets/icons/Icons';

import { Category } from './Messages.type';

export enum CATEGORY_SLUG {
  ADMIN = 'admin',
  BILLING = 'billing',
  MEDICAL = 'medical',
  TECH = 'tech',
  BENEFITS = 'benefits',
  EMPLOYER_BENEFIT = 'employer-benefit'
}

export const CATEGORIES_INFO: Category[] = [
  {
    title: 'Admin',
    description: 'Help with scheduling, medical records, referrals.',
    slug: CATEGORY_SLUG.ADMIN,
    icon: <UserGear type="light" />
  },
  {
    title: 'Billing Team',
    description:
      'Insurance, billing, and balance issues or questions, and itemizations of previous costs and services.',
    slug: CATEGORY_SLUG.BILLING,
    icon: <MoneyCheckDollar type="light" />
  },
  {
    title: 'Medical Team',
    description: 'Care plan, medications, results.',
    slug: CATEGORY_SLUG.MEDICAL,
    icon: <StaffSnake type="light" />
  },
  {
    title: 'Tech Support',
    description: 'Technical issues with your patient portal.',
    slug: CATEGORY_SLUG.TECH,
    icon: <Headset type="solid" />
  },
  {
    title: 'Employer Benefit',
    description:
      'Questions about Kindbody benefit coverage, benefit authorizations, and the cost of services.',
    slug: CATEGORY_SLUG.EMPLOYER_BENEFIT,

    icon: <HandsHoldingHeart type="regular" />
  },
  {
    title: 'Benefits',
    description:
      'Questions about insurance coverage, insurance authorizations, and the cost of services.',
    slug: CATEGORY_SLUG.BENEFITS,
    icon: <HandHoldingHeart type="regular" />
  }
];

export const DEFAULT_CATEGORIES_INFO = CATEGORIES_INFO[0];
