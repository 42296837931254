import { useQuery, useMutation, ApolloError, NetworkStatus } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Query } from '@apollo/client/react/components';
import AuthFlowLayout from 'components/AuthFlowLayout';
import { Loader } from 'components/Loader/Loader';
import MobileStepTracker from 'components/MobileStepTracker/MobileStepTracker';
import StepTracker from 'components/StepTracker';
import { TODOS } from 'kb-shared/graphql/queries';
import { BugTracker } from 'kb-shared/utilities/bugTracker';
import { analytics } from 'utilities/analytics';
import { showErrorToast } from 'utilities/notificationUtils';

import Comprehension from './Components/Comprehension/Comprehension';
import { COMPLETE_FORM, LOAD_CONSENT, OPEN_PATIENT_CONSENT } from './InformedConsent.graphql';
import {
  Container,
  Content,
  MobileStepTrackerWrapper,
  PageTitle,
  RightContentWrapper,
  SubTitle
} from './InformedConsent.styled';
import {
  Consent,
  ConsentData,
  CompleteFormResponseData,
  StepType,
  Step,
  Steps,
  OpenPatientContentData,
  ConsentAdditionalSteps
} from './InformedConsent.types';
import { AdditionalOptions } from './Steps/AdditionalOptions';
import { Error } from './Steps/Error';
import { FinalStep } from './Steps/FinalStep';
import { YourConsent } from './Steps/YourConsent/YourConsent';

export const InformedConsent = () => {
  const { id } = useParams<{ id: string }>();
  const consentId = parseInt(id);
  const initialStepType = 'your_consent';
  const initialStep: Step = {
    type: initialStepType,
    name: Steps[initialStepType],
    partOfNavigation: stepsForNavigation.includes(initialStepType)
  };
  const [allSteps, setAllSteps] = useState<Array<Step>>([initialStep]);
  const [currentStep, setCurrentStep] = useState<Step>(initialStep);
  const [signedPatientConsentId, setSignedPatientConsentId] = useState<string>('');
  const [consent, setConsent] = useState<Consent | undefined>(undefined);
  const [completeForm, { loading: completeFormLoading }] = useMutation<
    CompleteFormResponseData,
    { patientConsentId: number; versionUuid: string | null }
  >(COMPLETE_FORM, {
    variables: {
      patientConsentId: parseInt(signedPatientConsentId),
      versionUuid: consent?.patientConsent.versionUuid || null
    },
    refetchQueries: [{ query: TODOS }, { query: OPEN_PATIENT_CONSENT, variables: { consentId } }],
    onError: error => {
      if (isExpectedError(error.message)) {
        handleErrorMessage(error);
        return;
      }
      showErrorToast('An error occurred: ' + error.message);
      BugTracker.notify(error, 'Failed to complete patient consent');
    },
    onCompleted: () => {
      const stepType = 'final_step';
      const finalStep: Step = {
        type: stepType,
        name: Steps[stepType],
        partOfNavigation: stepsForNavigation.includes(stepType)
      };
      setCurrentStep(finalStep);
      analytics.track(analytics.EVENTS.PATIENT_CONSENT_SIGNED);
    }
  });

  const {
    data: openPatientConsentData,
    error: errorOpenPatientConsent,
    loading: loadingOpenPatientConsent,
    networkStatus: networkStatusOpenPatientConsent
  }: OpenPatientContentData = useQuery(OPEN_PATIENT_CONSENT, {
    variables: { consentId: consentId }
  });

  useEffect(() => {
    analytics.track(analytics.EVENTS.PATIENT_CONSENT_VIEWED);
  }, []);

  const goToNextStep = async () => {
    const currentStepIndex = allSteps.findIndex(step => step.type === currentStep.type);
    const isLastStepBeforeFinal = currentStepIndex === allSteps.length - 1;

    if (isLastStepBeforeFinal) {
      await completeForm();
      analytics.track(analytics.EVENTS.PATIENT_CONSENT_SIGNED);
    } else {
      setCurrentStep(allSteps[currentStepIndex + 1]);
    }
  };

  const renderCurrentStep = (patientConsentId: string) => {
    return (
      <Query
        query={LOAD_CONSENT}
        variables={{ consentId: Number(patientConsentId) }}
        onError={(error: ApolloError) => BugTracker.notify(error, 'Consent Fetch')}
        onCompleted={(loadedConsent: Consent) => {
          setConsent(loadedConsent);
          if (loadedConsent?.patientConsent == null) return;

          const steps = [initialStep];
          if (loadedConsent.patientConsent.consent?.consentDecisions?.length) {
            const stepType: StepType = 'additional_options';
            steps.push({
              type: stepType,
              name: Steps[stepType],
              partOfNavigation: stepsForNavigation.includes(stepType)
            });
          }

          if (loadedConsent.patientConsent.consent?.comprehensionQuestions?.length) {
            const stepType: StepType = 'comprehension';
            steps.push({
              type: stepType,
              name: Steps[stepType],
              partOfNavigation: stepsForNavigation.includes(stepType)
            });
          }

          setAllSteps(steps);
        }}
      >
        {({ data: consentData, error, loading, networkStatus }: ConsentData) => {
          if (error || networkStatus === NetworkStatus.error) return <Error />;

          // patientConsent is patient's instance of a consent
          const patientConsent = consentData?.patientConsent;

          if (loading || !patientConsent) return <Loader absoluteCentered />;

          const currentStepType = currentStep.type;

          // consent is the actual consent form associated with patientConsent
          const consent = patientConsent.consent;

          switch (currentStepType) {
            case 'your_consent':
              return (
                <YourConsent
                  consentId={consentId}
                  consentRawHtml={patientConsent.patientPortalHtml || ''}
                  pdf={patientConsent.pdfLink}
                  hasMultipleSteps={hasAdditionalSteps(consent)}
                  onAgree={(patientConsentId: string) => {
                    setSignedPatientConsentId(patientConsentId);
                    goToNextStep();
                  }}
                />
              );
            case 'additional_options':
              return (
                <AdditionalOptions
                  patientConsentId={signedPatientConsentId}
                  consentDecisions={consent?.consentDecisions || []}
                  onCompleted={goToNextStep}
                />
              );
            case 'comprehension':
              const questions = consent?.comprehensionQuestions;

              return (
                <Comprehension
                  consentId={consentId}
                  patientConsentId={signedPatientConsentId}
                  questions={questions || []}
                  onCompleted={submit => {
                    if (!submit) return;
                    goToNextStep();
                  }}
                />
              );
            default:
              return null;
          }
        }}
      </Query>
    );
  };

  const onStepSelected = (selectedStepIndex: number) => {
    setCurrentStep(allSteps[selectedStepIndex]);
  };

  const isFormFetched = Boolean(consent?.patientConsent);
  const pageTitle = getPageTitle(currentStep.type);
  const pageSubTitle = getSubTitle(currentStep.type);
  const selectedStepIndex = allSteps.findIndex(step => step.type === currentStep.type);
  const navigationStepsNames = allSteps
    .filter(step => step.partOfNavigation)
    .map(step => step.name);

  const patientConsentId = openPatientConsentData?.openPatientConsent?.id || '';

  const isError =
    errorOpenPatientConsent || networkStatusOpenPatientConsent === NetworkStatus.error;
  const isLoading = loadingOpenPatientConsent || completeFormLoading;
  const isFinalStep =
    !openPatientConsentData?.openPatientConsent ||
    (signedPatientConsentId && signedPatientConsentId !== patientConsentId);

  if (isError) return <Error />;
  else if (isLoading) return <Loader absoluteCentered />;
  else if (isFinalStep) return <FinalStep consentId={consentId} />;

  return (
    <AuthFlowLayout
      title={''}
      renderAboveTitleContent={(mobile: boolean) => {
        if (mobile && isFormFetched) {
          return (
            <>
              {pageTitle && <PageTitle>{pageTitle}</PageTitle>}
              {pageSubTitle && <SubTitle>{pageSubTitle}</SubTitle>}
              <MobileStepTrackerWrapper>
                <MobileStepTracker
                  steps={navigationStepsNames}
                  selectedStep={selectedStepIndex}
                  onStepSelected={onStepSelected}
                />
              </MobileStepTrackerWrapper>
            </>
          );
        } else {
          return (
            <>
              {pageTitle && <PageTitle>{pageTitle}</PageTitle>}
              {pageSubTitle && <SubTitle>{pageSubTitle}</SubTitle>}
            </>
          );
        }
      }}
      renderMainContent={() => {
        return (
          <Container>
            <Content>{renderCurrentStep(patientConsentId)}</Content>
          </Container>
        );
      }}
      renderDesktopRightSidebar={() => {
        if (!isFormFetched) return null;

        return (
          <RightContentWrapper>
            <StepTracker
              steps={navigationStepsNames}
              selectedStep={selectedStepIndex}
              onSelectStep={onStepSelected}
            />
          </RightContentWrapper>
        );
      }}
    />
  );
};

const isExpectedError = (message: string) =>
  ['UNPROCESSABLE_ENTITY', 'CANT_COMPLETE_CONSENT', 'OUT_OF_DATE_VERSION'].includes(message);

const handleErrorMessage = (error: any): void => {
  const messageText = getGraphQLErrorMessages(error);

  showErrorToast('An error occurred: ' + messageText);
  return;
};

// error structure error.graphQLErrors[0].extensions.message
const getGraphQLErrorMessages = (error: any): string => {
  if (!error['graphQLErrors']) return error.message || 'Something went wrong. Please try again.';

  return error['graphQLErrors']
    .map((graphQLError: any) => graphQLError.extensions.message)
    .join(' ');
};

const getSubTitle = (step: StepType) => {
  if (step === 'additional_options') {
    return 'Please choose one of the options below. As part of your consent, we need to have a decision on file before we can move forward with any procedures.';
  }

  return '';
};

const getPageTitle = (step: StepType) => {
  switch (step) {
    case 'your_consent':
      return '';
    case 'additional_options':
      return 'Let’s go over a few more things:';
    case 'comprehension':
      return 'Let’s go over a few more things:';
    default:
      return '';
  }
};

const stepsForNavigation: Array<StepType> = ['your_consent', 'additional_options', 'comprehension'];

const hasAdditionalSteps = (additionalSteps: ConsentAdditionalSteps | null) => {
  if (!additionalSteps) return false;

  return (
    (Array.isArray(additionalSteps.consentDecisions) &&
      additionalSteps.consentDecisions.length > 0) ||
    (Array.isArray(additionalSteps.comprehensionQuestions) &&
      additionalSteps.comprehensionQuestions?.length > 0)
  );
};
